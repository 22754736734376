<!--
 * @version: v 1.0.0
 * @Github: https://github.com/GitHubGanKai
 * @Author: GitHubGanKai
 * @Date: 2021-01-01 13:57:27
 * @LastEditors: gankai
 * @LastEditTime: 2021-01-02 15:07:37
 * @FilePath: /refactor-with-vue3/src/components/tabbar/index.vue
-->
<template>
  <div class="tabbar">
    <van-tabbar
      route
      v-model="active"
      @change="tabChange(active)"
      active-color="#E62425"
      inactive-color="#7B7B7B"
      :safe-area-inset-bottom="true"
    >
      <van-tabbar-item name="index"  to="/index">
        <span>首页</span>
        <template #icon="props">
          <img :src="props.active ? imgs[0][0] : imgs[0][1]" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="mine"  to="/mine">
        <span>我的</span>
        <template #icon="props">
          <img :src="props.active ? imgs[1][0] : imgs[1][1]" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import {computed, ref, getCurrentInstance, reactive} from "vue";
import { useStore } from "vuex";
import  homeActive from '@/assets/tabbar/icon_home_on.png'
import  homeNormal from '@/assets/tabbar/icon_home_off.png'
import  mineActive from '@/assets/tabbar/icon_my_on.png'
import  mineNormal from '@/assets/tabbar/icon_my_off.png'

export default {
  name: "tabbar",
  setup(props, context) {
    const { attrs, emit, slots } = context;
    const currentInstance = getCurrentInstance();

    const { ctx } = currentInstance;
    const active = ref(0);
    const store = useStore();

    const imgs = reactive(
        [
          [homeActive,homeNormal],
            [mineActive,mineNormal],

        ]
    )

    // ctx.$eventBus.$on(
    //   "changeTag",
    //   tag => {
    //     active.value = tag;
    //   },
    //   currentInstance
    // );

    return {
      imgs,
      active,
    };
  },
  methods: {
    tabChange(active) {
      console.log("==active=====>", active);
    }
  }
};
</script>

<style scoped>

.van-tabbar-item {
  font-weight: bold;
}

</style>
