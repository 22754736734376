import {Toast} from "vant";
import * as THREE from 'three/build/three.module'

export const loginToPlatform = ($http)=>{

  // window['transferUserDataToWeb']=(uid,urid,token) => {
  //
  //   let param = {
  //     uid: uid,
  //     urid: urid,
  //     token: token
  //   }
  //   $http.post("/wenwu-user/user/green/channel/hongwang", param).then(res => {
  //     showSuccessToast(JSON.stringify(res.data))
  //     clearToast()
  //     showSuccessToast('登录成功')
  //     setStore("token", res.data.data.satoken)
  //     setStore("isValid", res.data.data.isValid)
  //     setTimeout(() => {
  //       location.reload()
  //     }, 1000);
  //   }).catch((err) => {
  //     showFailToast('登录失败')
  //   })
  // }
  //
  // const getHwToken = (() => {
  //   //获取红网登录令牌
  //   var u = navigator.userAgent;
  //   var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
  //   var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  //   if (isAndroid) {
  //     verifyJsBridge.postNotification('requireUserToLogin', '{}');
  //   } else {
  //     window.webkit.messageHandlers.requireUserToLogin.postMessage('');
  //   }
  // })
  //
  // getHwToken()


}


export const showLoading = (message = '加载中')=>{
  Toast.loading({
    duration: 0,
    message:  message,
    forbidClick: true,
    loadingType: 'circular',
  });
}

export const clearToast = ()=>{
  Toast.clear();
}

export const getInervalHour = (startDate, endDate)=>{
  let ms = endDate.getTime() - startDate.getTime();
  if (ms < 0) return 0;
  return Math.floor(ms/1000/60/60);
}


export default class ResourceTracker {
  constructor() {
    this.resources = new Set();
  }
  track(resource) {
    if (!resource) {
      return resource;
    }

    // handle children and when material is an array of materials or
    // uniform is array of textures
    if (Array.isArray(resource)) {
      resource.forEach(resource => this.track(resource));
      return resource;
    }

    if (resource.dispose || resource instanceof THREE.Object3D) {
      this.resources.add(resource);
    }
    if (resource instanceof THREE.Object3D) {
      this.track(resource.geometry);
      this.track(resource.material);
      this.track(resource.children);
    } else if (resource instanceof THREE.Material) {
      // We have to check if there are any textures on the material
      for (const value of Object.values(resource)) {
        if (value instanceof THREE.Texture) {
          this.track(value);
        }
      }
      // We also have to check if any uniforms reference textures or arrays of textures
      if (resource.uniforms) {
        for (const value of Object.values(resource.uniforms)) {
          if (value) {
            const uniformValue = value.value;
            if (uniformValue instanceof THREE.Texture ||
              Array.isArray(uniformValue)) {
              this.track(uniformValue);
            }
          }
        }
      }
    }
    return resource;
  }
  untrack(resource) {
    this.resources.delete(resource);
  }
  dispose() {
    for (const resource of this.resources) {
      if (resource instanceof THREE.Object3D) {
        if (resource.parent) {
          resource.parent.remove(resource);
        }
      }
      if (resource.dispose) {
        resource.dispose();
      }
    }
    this.resources.clear();
  }
}




export const showSuccessToast = (message,duration = 1000)=>{
  Toast.success({
    message: message,
    duration:duration
  });
}

export const showFailToast = (message,duration = 1000)=>{
  Toast.fail({
    message: message,
    duration:duration
  });
}

export const  isWeixinBrowser = () => {
  let ua = navigator.userAgent.toLowerCase();
  return /micromessenger/.test(ua) ? true : false;
}


export const createNonceStr = ()=>{
  let chars = ['0','1','2','3','4','5','6','7','8','9','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];
  let nums= " ";
  for(let i=0;i < 16 ;i++){//这里是几位就要在这里不改变
    let id = parseInt(Math.random()*61);
    nums+=chars[id];
  }
  return nums;
}


export const InputFloat = {
  bind: (el, bind) => {
    const input =
      el.nodeName.toLowerCase() === "input"
        ? el
        : el.getElementsByTagName("input")[0];
    // 改变值过后，需要主动触发input时间，组件绑定的值才会改变
    let triggerBySelf = false;
    if (input) {
      input.addEventListener("input", () => {
        if (triggerBySelf) {
          triggerBySelf = false;
          return;
        }
        // 将千分为值转换成正常的数值，如果input type为number 不需要千分位，可把第一个repalce正则删除
        let val = input.value.replace(/,/gi, "").replace(/[^0-9.]/gi, "");
        // 验证数字
        if (val === "") {
          input.value = "";
          return;
        }
        const valArr = val.split(".");

        if (bind.expression) {
          try {
            const option = bind.value;
            if (typeof option === "object") {
              const { limit, min, max } = option;
              if (limit !== undefined) {
                if (valArr.length > 1) {
                  let pointLength = valArr[1].length;
                  pointLength = limit > pointLength ? pointLength : limit;
                  if (limit > 0) {
                    val =
                      +valArr[0] +
                      (valArr.length > 1
                        ? `.${valArr[1].substr(0, pointLength)}`
                        : "");
                  } else {
                    val = +valArr[0] + "";
                  }
                } else {
                  val = +val + "";
                }
              }
              if (typeof min === "number" && +val < +min) {
                val = min + "";
              }
              // 数值大于最大值，不可再输入
              if (typeof max === "number" && +val > +max) {
                //以下控制显示的最大数为当前输入的可取最大值
                let nVal = val.split(".")[0];
                const pointVal = val.split(".")[1] ? val.split(".")[1] : "";
                const maxStringLength = String(max).split(".")[0].length;
                nVal = nVal.slice(0, maxStringLength);
                val = nVal + "." + pointVal;
                //以下是当值大于最大值 直接赋值为最大值
                // val = max + "";
              }
              //数值转换为千分为，如果不需要千分位展示 可以直接删除
              val = String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              // 改变值过后，需要主动触发input时间，组件绑定的值才会改变
              const ev = new Event("input", { bubbles: true });
              input.value = val;
              triggerBySelf = true;
              input.dispatchEvent(ev);
            }
          } catch (err) {
            input.value = val;
            console.error(err);
          }
        }
      });
    }
  }
};


// 将base64转换为blob
export const dataURLtoFile  = (dataURI, type) =>{
  let binary = atob(dataURI.split(',')[1]);
  let array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], {type: type});
}

//转成对应格式文件
/**
 * dataurl: base64
 * filename: 设置文件名称
 */
export const dataURLtoFileUp = (dataurl, filename) => {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {
    type: mime
  });
}

// 存储SessionlocalStorage
export const setSessionStore = (name, content) => {
  if (!name) return
  sessionStorage.setItem(name, JSON.stringify(content))
}

// 获取sessionStorage
export const getSessionStore = name => {
  if (!name) return
  return JSON.parse(sessionStorage.getItem(name))
}

// 获取sessionStorage
export const removeSessionStore = name => {
  if (!name) return
  return sessionStorage.removeItem(name)
}

// 存储localStorage
export const setStore = (name, content) => {
  if (!name) return
  localStorage.setItem(name, JSON.stringify(content))
}

// 获取localStorage
export const getStore = name => {
  if (!name) return
  return JSON.parse(localStorage.getItem(name))
}

// 获取localStorage
export const removeStore = name => {
  if (!name) return
  return localStorage.removeItem(name)
}

export function formValidate(val, type) {
  const phoneReg = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/
  const emailReg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/
  if (val === '') {
    return false
  } else {
    // 非空验证
    if (type === 'require') {
      return !!removeSpace(val)
    }
    if (type === 'phone') {
      return phoneReg.test(val)
    }
    if (type === 'email') {
      return emailReg.test(val)
    }
  }
}

export function  changeToDate(dateString){
  return  new Date(parseInt(dateString.slice(0, 4))-0, parseInt(dateString.slice(4, 6))-1, parseInt(dateString.slice(6, 8))-0, parseInt(dateString.slice(8,10))-0, parseInt(dateString.slice(10,12))-0,
    parseInt(dateString.slice(12,14))-0,parseInt(dateString.slice(14,17))-0)
}

export function formatDate(params ) {
  let defalutParams = {
    date: new Date(),
    formatStr: 'yyyy-MM-dd HH:mm:ss'
  }
  params = { ...defalutParams, ...params };
  let date = params.date;
  let formatStr = params.formatStr;
  // 传入日期字符串 - 转成时间戳 - 转成标准时间
  let timeStamp = new Date(date).getTime();
  date = new Date(timeStamp);
  formatStr = formatStr.replace(new RegExp('yyyy'), `${date.getFullYear()}`);
  const month = date.getMonth() + 1;
  formatStr = formatStr.replace(new RegExp('MM'), `${month > 9 ? month : '0' + month}`);
  const day = date.getDate();
  formatStr = formatStr.replace(new RegExp('dd'), `${day > 9 ? day : '0' + day}`);
  const hour = date.getHours();
  formatStr = formatStr.replace(new RegExp('HH'), `${hour > 9 ? hour : '0' + hour}`);
  const min = date.getMinutes();
  formatStr = formatStr.replace(new RegExp('mm'), `${min > 9 ? min : '0' + min}`);
  const sec = date.getSeconds();
  formatStr = formatStr.replace(new RegExp('ss'), `${sec > 9 ? sec : '0' + sec}`);
  return formatStr;

}

